// in gastby-browser.js
exports.shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition,
}) => {
    // if a location hash is defined, try parsing it
    if (location.hash && location.hash.length > 1) {
        const elem = document.getElementById(location.hash.substring(1));

        if (elem) {
            const elemTop = elem.clientTop;
            window.scrollTo(0, elemTop);
            return true;
        }

    } else {
        window.scrollTo(0, 0)
    }

    return false
}